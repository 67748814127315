import { Button } from "@material-ui/core";
import VegLogo from "img/veg_logo.png";

const LandingStep = ({ handleClick }) => {
  return (
    <div
      style={{
        padding: "2rem",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100% - 4rem)",
      }}
    >
      <div>
        <div>
          <img
            src={VegLogo}
            alt="VEG Logo"
            style={{ width: "69%", marginBottom: "1rem" }}
          />
          <p>Welcome to VEG's Self Check-in.</p>
          <p>
            To help us give your pet the very best care, please tell us a little
            more about them.
          </p>
        </div>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClick}
          style={{
            marginTop: "1rem",
          }}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default LandingStep;
