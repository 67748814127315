import { Header } from "components/lib";
import checkCircleFilled from "../../../img/CheckCircleFilled.svg"
import { useSelector } from "react-redux";

const Success = () => {
  const { hospitalData } = useSelector((state) => {return state.checkin;});
  const hospitalAddress = `${hospitalData?.street}, ${hospitalData?.city}, ${hospitalData?.state} ${hospitalData?.zipcode}`
  return (
    <div>
      <Header>Thank you</Header>
      <div
        style={{
          textAlign: "center",
          padding: "0 2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 96px)",
        }}
      >
        <div>
          <img src={checkCircleFilled} style={{ margin: "1.5rem 0" }} />
          <p>Thank you for providing us with information about you and your pet.</p>
          <div>
            We are ready to help at:
          </div>
          <div>
            VEG - {hospitalData?.display_name}
          </div>
          <span>
            {hospitalAddress}
          </span>
          <br/>
          <span>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(hospitalAddress)}`}
              target="_blank"
              rel="noreferrer"
            >
              See in Google Maps
            </a>
          </span>
            <p>
              We can be reached by phone at <a href={`tel:${hospitalData?.phone_number}`}>{hospitalData?.phone_number}</a> with any questions or concerns.
            </p>
        </div>
      </div>
    </div>
  );
};

export default Success;
