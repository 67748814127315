import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CheckinForm, CheckinSuccess } from "./components/view";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/success" component={CheckinSuccess} />
        <Route exact path="/checkin/:id" component={CheckinForm} />
      </Switch>
    </Router>
  );
};

export default App;
