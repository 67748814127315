import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customer_first_name: "",
  customer_last_name: "",
  customer_phone: "",
  customer_email: "",
  pet_name: "",
  pet_birthday: "",
  pet_species: null,
  pet_breed: null,
  pet_sex: "",
  ageYears: "",
  ageMonths: "",
  spayedOrNeutered: "",
  reason_for_visit: "",
  how_did_you_hear_about_us: "",
  additional_info: "",
  checked_opt_in_marketing_email: true,
  rdvm: null,
  customer_is_owner: true,
  customer_addr_street: "",
  customer_addr_city: "",
  customer_addr_state: null,
  customer_addr_zipcode: "",
  hospitalData: null,
};

const slice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.customer_first_name = action.payload;
    },
    setLastName: (state, action) => {
      state.customer_last_name = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.customer_phone = action.payload;
    },
    setEmail: (state, action) => {
      state.customer_email = action.payload;
    },
    setPetName: (state, action) => {
      state.pet_name = action.payload;
    },
    setBirthday: (state, action) => {
      state.pet_birthday = action.payload;
    },
    setAgeYears: (state, action) => {
      state.ageYears = action.payload;
    },
    setAgeMonths: (state, action) => {
      state.ageMonths = action.payload;
    },
    setSpecies: (state, action) => {
      state.pet_species = action.payload;
    },
    setBreed: (state, action) => {
      state.pet_breed = action.payload;
    },
    setSex: (state, action) => {
      state.pet_sex = action.payload;
    },
    setspayedOrNeutered: (state, action) => {
      state.spayedOrNeutered = action.payload;
    },
    setVisitReason: (state, action) => {
      state.reason_for_visit = action.payload;
    },
    setHowDidYouHearAboutUs: (state, action) => {
      state.how_did_you_hear_about_us = action.payload;
    },
    setAdditionalInformation: (state, action) => {
      state.additional_info = action.payload;
    },
    setCheckedOptInMarketingEmail: (state, action) => {
      state.checked_opt_in_marketing_email = action.payload;
    },
    set_rdvm: (state, action) => {
      state.rdvm = action.payload;
    },
    set_customer_is_owner: (state, action) => {
      state.customer_is_owner = action.payload;
    },
    set_customer_addr_street: (state, action) => {
      state.customer_addr_street = action.payload;
    },
    set_customer_addr_city: (state, action) => {
      state.customer_addr_city = action.payload;
    },
    set_customer_addr_state: (state, action) => {
      state.customer_addr_state = action.payload;
    },
    set_customer_addr_zipcode: (state, action) => {
      state.customer_addr_zipcode = action.payload;
    },
    setHospitalData: (state, action) => {
      state.hospitalData = action.payload;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setPhoneNumber,
  setEmail,
  setAddress,
  setPetName,
  setBirthday,
  setAgeYears,
  setAgeMonths,
  setSpecies,
  setBreed,
  setSex,
  setspayedOrNeutered,
  setVisitReason,
  setHowDidYouHearAboutUs,
  setAdditionalInformation,
  setCheckedOptInMarketingEmail,
  set_rdvm,
  set_customer_is_owner,
  set_customer_addr_street,
  set_customer_addr_city,
  set_customer_addr_state,
  set_customer_addr_zipcode,
  setHospitalData,
} = slice.actions;

export const reducer = slice.reducer;
