import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "utils/helpers";

const useStyles = makeStyles({
  ul: {
    "& li": {
      padding: "6px 10px",
      marginBottom: "6px",
      fontSize: "0.9rem",
    },
  },
  header: {
    background: "#e4e4e4",
    padding: "6px 10px",
    fontSize: "13px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  detailWrapper: {
    border: "1px solid #e2e2e2",
    borderRadius: 3,
  },
});

const NOT_PROVIDED_STRING = "Not provided"

const OverviewStep = () => {
  const {
    customer_first_name,
    customer_last_name,
    customer_phone,
    customer_email,
    pet_name,
    pet_birthday,
    pet_species,
    pet_breed,
    pet_sex,
    reason_for_visit,
    rdvm,
    how_did_you_hear_about_us,
    additional_info,
    checked_opt_in_marketing_email,
    customer_addr_street,
    customer_addr_city,
    customer_addr_state,
    customer_addr_zipcode,
    spayedOrNeutered,
  } = useSelector((state) => state.checkin);

  const classes = useStyles();


  let addressString = '';

  if(customer_addr_street){
    addressString += customer_addr_street
  }
  if(customer_addr_city){
    if(addressString !== ''){
      addressString += ", "
    }
    addressString += customer_addr_city
  }
  if(customer_addr_state){
    if(addressString !== ''){
      addressString += ", "
    }
    addressString += customer_addr_state.value
  }
  if(customer_addr_zipcode){
    if(addressString !== ''){
      addressString += ", "
    }
    addressString += customer_addr_zipcode
  }

  return (
    <div style={{ padding: "2rem" }}>
      <div className={classes.detailWrapper}>
        <ul className={classes.ul}>
          <div className={classes.header}>Owner Details</div>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Name</b>
            </div>
            <div>
            {customer_first_name || customer_last_name ? customer_first_name + " " +customer_last_name : NOT_PROVIDED_STRING}
            </div>
          </li>

          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Phone No.</b>
            </div>
            <div>{customer_phone && formatPhoneNumber(customer_phone) ? formatPhoneNumber(customer_phone) : NOT_PROVIDED_STRING}</div>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Email</b>
            </div>
            <div>{customer_email ? customer_email : NOT_PROVIDED_STRING}</div>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Address</b>
            </div>
            <div>
              {addressString !== '' ? addressString : NOT_PROVIDED_STRING}
            </div>
          </li>
        </ul>
        <div className={classes.header}>Patient Details</div>

        <ul className={classes.ul}>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Name</b>
            </div>
            <div>{pet_name ? pet_name : NOT_PROVIDED_STRING}</div>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Species</b>
            </div>
            {pet_species ? pet_species.label : NOT_PROVIDED_STRING}
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Breed</b>
            </div>
            {pet_breed ? pet_breed.label : NOT_PROVIDED_STRING}
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Birthday</b>
            </div>
            <div>{pet_birthday ? pet_birthday: NOT_PROVIDED_STRING}</div>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Sex</b>
            </div>
            {pet_sex ? pet_sex :  NOT_PROVIDED_STRING}
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Is patient spayed / neutered?</b>
            </div>
            <div>{spayedOrNeutered ? "Yes" : spayedOrNeutered === false ? "No" :  NOT_PROVIDED_STRING}</div>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Reason for visit</b>
            </div>
            {reason_for_visit ? reason_for_visit : NOT_PROVIDED_STRING}
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <b>Primary Vet</b>
            </div>
            {rdvm
                ? rdvm.display_name
                : NOT_PROVIDED_STRING}
          </li>
          <div className={classes.header}>Additional Details</div>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>How did you hear about us?</b>
            <div>
              {how_did_you_hear_about_us ? how_did_you_hear_about_us : NOT_PROVIDED_STRING}
            </div>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Additional info</b>
            <div>
              {additional_info ? additional_info : NOT_PROVIDED_STRING}
            </div>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Updates on VEG and pet safety?</b>
            <div>
              {checked_opt_in_marketing_email ? 'Yes' : 'No'}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OverviewStep;
