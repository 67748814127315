import { Header } from "components/lib";
import checkCircleFilled from "../../../img/CheckCircleFilled.svg"

const AlreadySubmitted = () => {
  return (
    <div>
      <Header>Already submitted</Header>
      <div
        style={{
          textAlign: "center",
          padding: "0 2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 96px)",
        }}
      >
        <div>
          <img src={checkCircleFilled} style={{ margin: "1.5rem 0" }} />
          <p>This form has already been submitted.</p>
          <p>Thank you for providing us with information about you and your pet.</p>
        </div>
      </div>
    </div>
  );
};

export default AlreadySubmitted;
