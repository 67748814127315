import { useSelector, useDispatch } from "react-redux";
import { useState, forwardRef, useImperativeHandle } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useGetRdvmQuery } from "services/checkin.service";
import { set_rdvm } from "utils/slice";
import { useDebounce } from "hooks";
import {
  setPetName,
  setBirthday,
  setSpecies,
  setBreed,
  setSex,
  setspayedOrNeutered,
  setVisitReason,
  setAgeYears,
  setAgeMonths,
} from "utils/slice";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Grid
} from "@material-ui/core";
import moment from 'moment';
import { useGetSpeciesQuery, useGetBreedsQuery } from "services/checkin.service";
import { US_STATES } from "utils/consts";


const PetStep = forwardRef ((props, ref) => {
  const {
    pet_name,
    pet_birthday,
    pet_species,
    pet_breed,
    pet_sex,
    spayedOrNeutered,
    reason_for_visit,
    rdvm,
    ageYears,
    ageMonths
  } = useSelector((state) => {
    return state.checkin;
  });

  const [rdvmSearch, setRDVMSearch] = useState("");
  const [nameFilled, setNameFilled] = useState(true)
  const [speciesFilled, setSpeciesFilled] = useState(true)
  const [breedFilled, setBreedFilled] = useState(true)
  const [sexFilled, setSexFilled] = useState(true)
  const [rdvmFilled, setRDVMFilled] = useState(true)
  const [validBirthday, setValidBirthday] = useState(true)

  const { data: rdvmsData } = useGetRdvmQuery({ hospitalId: props.hospitalId });

  const dispatch = useDispatch();

  const {data: species} = useGetSpeciesQuery();
  const {data: breeds} = useGetBreedsQuery();

  const breedOptions =
    breeds?.filter((breed) => breed.species_id === pet_species?.value)
    .map((breed) => ({
      value: breed.id,
      label: breed.name,
    })) ?? []


  const validateForm = () => {
    setNameFilled(!!pet_name)
    setSpeciesFilled(!!pet_species)
    setSexFilled(!!pet_sex)
    setRDVMFilled(!!rdvm)

    if (breedOptions.length) {
      setBreedFilled(!!pet_breed)
    } else {
      setBreedFilled(true)
    }

    let validBirthday = true
    if (!pet_birthday) {
      setValidBirthday(true)
      validBirthday = true
    } else {
      const actualDate = moment();
      if (moment(pet_birthday).isBefore(actualDate)) {
        setValidBirthday(true)
        validBirthday = true
      } else {
        setValidBirthday(false)
        validBirthday = false
      }
    }

    if (pet_name && pet_species && pet_sex && rdvm && validBirthday) {
      if (breedOptions.length && !pet_breed) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  };

  useImperativeHandle(ref, () => ({
    validateForm: validateForm
  }));

  const calculateBirthday = (units, amount) => {
        let amountInt = parseInt(amount)
        if (!isNaN(amountInt) && amountInt >= 0) {
          if (units === "years") {
            dispatch(setAgeYears(amountInt))
            dispatch(setBirthday(moment().subtract(amountInt, 'years').subtract(ageMonths, 'months').format("YYYY-MM-DD")))
          } else {
            if (amountInt < 12) {
              dispatch(setAgeMonths(amountInt))
              dispatch(setBirthday(moment().subtract(ageYears, 'years').subtract(amountInt, 'months').format("YYYY-MM-DD")))
            }
          }
        } else if (amount === "") {
          if (units === "years") {
            dispatch(setAgeYears(""))
            dispatch(setBirthday(moment().subtract(0, 'years').subtract(ageMonths, 'months').format("YYYY-MM-DD")))
          } else {
            dispatch(setAgeMonths(""))
            dispatch(setBirthday(moment().subtract(ageYears, 'years').subtract(0, 'months').format("YYYY-MM-DD")))
          }
        }
    }


  const getRdvmOptions = () => {
    const regex = new RegExp(rdvmSearch.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

    return rdvmsData
      ?.filter((rdvm) => (rdvmSearch ? rdvm.display_name.match(regex) || rdvm.section_group === 0 : true))
      .map((option) => {
        let sectionTitle = '';

        if (option.section_group === 1) {
          sectionTitle = 'Nearby locations';
        }
        if (option.section_group === 2) {
          sectionTitle = 'More locations';
        }
        if (option.section_group === 3) {
          sectionTitle = US_STATES.find((state) => state.abbreviation === option.state)?.name;
        }

        return {
          sectionTitle,
          ...option,
        };
      });
  }


  return (
    <div style={{ padding: "2rem" }}>
      <TextField
        label="* Name"
        placeholder="Name"
        onChange={props.handleChange(setPetName)}
        defaultValue={pet_name}
        fullWidth
        margin="dense"
        // error={!requiredFieldsFilled["pet_name"]}
        error={!nameFilled}
      />
      {!nameFilled && <FormHelperText style={{color:"red"}}>The patient's name must be entered</FormHelperText>}
      <div style={{margin:'6px 0'}}>
        <span>* Species</span>
        <Autocomplete
          defaultValue={pet_species}
          size="small"
          id="combo-box-demo"
          options={species?.map((singleSpecies) => ({
						value: singleSpecies.id,
						label: singleSpecies.name,
					}))}
          getOptionLabel={(option) =>
            `${option.label}`
          }
          style={{ width: 300, marginTop:"10px" }}
          renderInput={(params) => (
            <TextField {...params} placeholder='Search...' variant="outlined" />
          )}
          onChange={(e, value) => {
            props.handleValChange(setSpecies)(value ?? null);
            props.handleValChange(setBreed)(null);
          }}
          error={!speciesFilled}
        />
      </div>
      {!speciesFilled && <FormHelperText style={{color:"red"}}>The patient's species must be entered</FormHelperText>}
      <div style={{margin:'6px 0'}}>
        <span>{breedOptions.length ? "* Breed" : "Breed"}</span>
        <Autocomplete
          value={pet_breed}
          size="small"
          id="combo-box-demo"
          disabled={ !pet_species }
          options={breedOptions}
          getOptionLabel={(option) =>
            `${option.label}`
          }
          style={{ width: 300, marginTop:"10px" }}
          renderInput={(params) => (
            <TextField {...params} placeholder='Search...' variant="outlined" />
          )}
          onChange={(e, value) => {
            props.handleValChange(setBreed)(value ?? null);
          }}
          error={!breedFilled}
        />
      </div>
      {!breedFilled && <FormHelperText style={{color:"red"}}>The patient's breed must be entered</FormHelperText>}
      <Grid container>
        <Grid xs={6} sm={5}>
          <TextField
            label="Birthday"
            onChange={props.handleBirthdayChange(setBirthday)}
            // defaultValue={pet_birthday}
            value={pet_birthday}
            margin="dense"
            type="date"
            InputLabelProps={{shrink:true}}
            error={!validBirthday}
            fullWidth
          />
          {!validBirthday && <FormHelperText style={{color:"red"}}>The birthday entered must be equal or less than the current date</FormHelperText>}
        </Grid>
        <Grid container xs={6} sm={7}>
          <Grid xs={2} sm={3}>
          </Grid>
          <Grid xs={3} sm={2} style={{marginTop:"0.5rem"}}>
            <p>Age:</p>
          </Grid>
          <Grid xs={3} sm={3}>
            <TextField
              label="Y"
              placeholder="Y"
              onChange={(e) => calculateBirthday("years", e.target.value)}
              value={ageYears}
              margin="dense"
            />
          </Grid>
          <Grid xs={1} sm={1}>
          </Grid>
          <Grid xs={3} sm={3}>
            <TextField
              label="M"
              placeholder="M"
              onChange={(e) => calculateBirthday("months", e.target.value)}
              value={ageMonths}
              margin="dense"
            />
          </Grid>
        </Grid>
      </Grid>
      <FormControl component="fieldset" fullWidth margin="dense">
        <FormLabel component="legend" fullWidth labelPlacement="start">
          * Sex
        </FormLabel>
        <RadioGroup
          aria-label="sex"
          name="sex"
          defaultValue={pet_sex}
          onChange={props.handleChange(setSex)}
          error={!sexFilled}
        >
          <div style={{ display: "flex" }}>
            <FormControlLabel value="F" control={<Radio />} label="Female" />
            <FormControlLabel value="M" control={<Radio />} label="Male" />
          </div>
        </RadioGroup>
        {!sexFilled && <FormHelperText style={{color:"red"}}>The patient's sex must be entered</FormHelperText>}
      </FormControl>
      <FormControl component="fieldset" fullWidth margin="dense">
        <FormControlLabel
          //   labelPlacement="top"
          control={
            <Checkbox
              checked={spayedOrNeutered}
              onChange={props.handleCheck(setspayedOrNeutered)}
            />
          }
          label="Is patient spayed / neutered?"
        />
      </FormControl>
      <div style={{margin:'6px 0'}}>
      <span>* Primary Vet</span>
      <Autocomplete
        defaultValue={rdvm}
        size="small"
        options={getRdvmOptions() ?? []}
        getOptionLabel={(option) => `${option.display_name}`}
        groupBy={(option) => option.sectionTitle}
        style={{ width: 300, marginTop: "10px" }}
        renderInput={(params) => (
          <TextField {...params} placeholder='Search...' variant="outlined" />
        )}
        filterOptions={(x) => x} // disable default filter function
        onSelect={(e) => {
          setRDVMSearch(e.target.value);
        }}
        onChange={(e, value) => {
          props.handleValChange(set_rdvm)(value);
        }}
        error={!rdvmFilled}
      />
      </div>
      {!rdvmFilled && <FormHelperText style={{color:"red"}}>The patient's primary vet must be entered</FormHelperText>}

      <TextField
        multiline
        maxRows={2}
        label="Reason for visit"
        placeholder="Reason for visit"
        onChange={props.handleChange(setVisitReason)}
        defaultValue={reason_for_visit}
        fullWidth
        margin="normal"
      />
    </div>
  );
});

export default PetStep;
