import React, {useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import OwnerStep from "./OwnerStep";
import PetStep from "./PetStep";
import HearAboutUsStep from "./HearAboutUsStep";
import OverviewStep from "./OverviewStep";
import LandingStep from "./LandingStep";
import CheckinAlreadySubmitted from "../CheckinAlreadySubmitted";
import CheckinExpired from "../CheckinExpired";
import { useCreateCheckinMutation, useGetCheckinQuery } from "services/checkin.service";
import { useHistory, useParams } from "react-router-dom";
import { Header } from "components/lib";
import {
  setAgeYears,
  setAgeMonths,
  setHospitalData
} from "utils/slice";
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

const formatFields = (fields, id) => {
  let { spayedOrNeutered, RDVMStep, rdvm, pet_birthday, pet_species, pet_breed, customer_addr_state, ...rest } = fields;
  let rdvm_id = null;

  if (pet_birthday === "") {
    pet_birthday = null;
  }

  if (pet_species !== null) {
    pet_species = pet_species.label;
  }

  if (pet_breed !== null) {
    pet_breed = pet_breed.label;
  }

  if (customer_addr_state !== null) {
    customer_addr_state = customer_addr_state.value;
  }

  let sex = rest.pet_sex;
  if (spayedOrNeutered) {
    sex += rest.pet_sex === "M" ? "N" : "S";
  }

  if (rdvm) {
    rdvm_id = rdvm.id;
  }

  // temp
  for (const key in rest) {
    if (rest[key] === "") {
      rest[key] = null;
    }
  }

  return { ...rest, pet_sex: sex, id: id, rdvm_id: rdvm_id, pet_birthday: pet_birthday, pet_species: pet_species, pet_breed: pet_breed, customer_addr_state: customer_addr_state };
};

const ProgressMobileStepper = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const { id: urlId } = useParams();
  const [createCheckin] = useCreateCheckinMutation();
  const formFields = useSelector((state) => state.checkin);
  const dispatch = useDispatch();
  const history = useHistory();
  const {data: checkinData} = useGetCheckinQuery(urlId);
  const formRef = useRef()

  dispatch(setHospitalData(checkinData?.hospital))

  //  note: We should try not to assume component logic in
  // state management logic
  const handleChange = (setValue) => (e) => {
    dispatch(setValue(e.target.value));
  };


  const calculateAge = (units, date) => {
      if (date) {
        const newAgeYears = moment().diff(moment(date), 'years')
        if (units === "years") {
          dispatch(setAgeYears(newAgeYears))
        } else {
          const newAgeMonths = moment().diff(moment(date), 'months') - (Number(newAgeYears) * 12)
          dispatch(setAgeYears(newAgeYears))
          dispatch(setAgeMonths(newAgeMonths))
        }
      } else {
        return 0
      }
    }

  const handleBirthdayChange = (setValue) => (e) => {
    dispatch(setValue(e.target.value));
    calculateAge("years", e.target.value)
    calculateAge("months", e.target.value)
  };

  const handleValChange = (setValue) => (value) => {
    dispatch(setValue(value));
  };

  const handleCheck = (setValue) => (e) => {
    dispatch(setValue(e.target.checked));
  };

  const handleNext = () => {
    if (activeStep >= steps.length - 1) {
      SubmitCheckin();
    } else if (activeStep !== 0) {
      if (formRef.current.validateForm()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  };

  const SubmitCheckin = () => {
    createCheckin(formatFields(formFields, urlId)).then((res) => {
      if (typeof res !== "undefined" && !res?.error?.originalStatus) {
        history.push("/success");
      }
    });
  };




  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = React.useMemo(() => {
    return [
      {
        Component: () => <LandingStep handleClick={handleNext} />,
        title: "Getting Started",
      },
      {
        Component: () => (
          <PetStep
            handleChange={handleChange}
            handleCheck={handleCheck}
            handleBirthdayChange={handleBirthdayChange}
            ref={formRef}
            handleValChange={handleValChange}
            hospitalId={checkinData?.hospital.id}
          />
        ),
        title: "Patient Information",
      },
      {
        Component: () => <OwnerStep handleChange={handleChange} ref={formRef} handleValChange={handleValChange} />,
        title: "Your Information",
      },
      {
        Component: () => <HearAboutUsStep handleChange={handleChange} handleCheck={handleCheck} ref={formRef} handleValChange={handleValChange}/>,
        title: "How did you hear about us?",
      },
      {
        Component: () => <OverviewStep handleChange={handleChange} />,
        title: "Confirm Information",
      },
    ];
  }, [checkinData]);

  // TODO: create components for these cases
  // sent actually means a link to this checkin is sent to the customer and "available to submit"
  // approved actually means "already submitted"
  if(!checkinData) {
    return <></>;
  }
  if(checkinData.status === "approved") {
    return (<CheckinAlreadySubmitted />);
  }
  else if(checkinData.status !== "sent") {
    return (<CheckinExpired />);
  }

  return (
    <>
      <Header>{steps[activeStep].title}</Header>
      <div style={{ height: "calc(100vh - 96px)" }}>
        {steps[activeStep].Component()}
      </div>
      <MobileStepper
        variant="progress"
        steps={steps.length}
        position="bottom"
        activeStep={activeStep}
        className={classes.root}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
            {activeStep < steps.length - 1 ? "Next" : "Confirm"}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </>
  );
};

export default ProgressMobileStepper;
