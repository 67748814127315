import { configureStore } from "@reduxjs/toolkit";
import { reducer } from "./slice";
import checkinApi from "services/emptySplitApi";

export const store = configureStore({
  reducer: {
    checkin: reducer,
    [checkinApi.reducerPath]: checkinApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableStateInvariant: false,
			immutableStateInvariant: false,
		})
    .concat(checkinApi.middleware)
});
