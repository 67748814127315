import { Header } from "components/lib";
import ErrorIcon from "@material-ui/icons/Error";

const Expired = () => {
  return (
    <div>
      <Header>Expired</Header>
      <div
        style={{
          textAlign: "center",
          padding: "0 2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 96px)",
        }}
      >
        <div>
          <ErrorIcon
            style={{ fontSize: "3rem", color: "red", margin: "1.5rem 0" }}
          />
          <p>This form link has expired.</p>
        </div>
      </div>
    </div>
  );
};

export default Expired;
