import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import {
  setFirstName,
  setLastName,
  setPhoneNumber,
  setEmail,
  set_customer_addr_street,
  set_customer_addr_city,
  set_customer_addr_state,
  set_customer_addr_zipcode,
} from "utils/slice";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { US_STATES } from "utils/consts";


const OwnerStep = forwardRef ((props, ref) => {
  const {
    customer_first_name,
    customer_last_name,
    customer_phone,
    customer_email,
    customer_addr_street,
    customer_addr_city,
    customer_addr_state,
    customer_addr_zipcode,
  } = useSelector((state) => state.checkin);

  const [firstNameFilled, setFirstNameFilled] = useState(true)
  const [lastNameFilled, setLastNameFilled] = useState(true)
  const [phoneFilled, setPhoneFilled] = useState(true)
  const [emailFilled, setEmailFilled] = useState(true)
  const [validZipCode, setValidZipCode] = useState(true)

  const [emailErrorMessage, setEmailErrorMessage] = useState("")
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState("")

  const validateForm = () => {
    setFirstNameFilled(!!customer_first_name)
    setLastNameFilled(!!customer_last_name)


    let phoneRegex = new RegExp("^[0-9]{10}$");
    let validCustomerPhone = false
    if (!customer_phone) {
      setPhoneErrorMessage("The customer's phone must be entered")
      setPhoneFilled(false)
      validCustomerPhone = false
    } else {
      if (isNaN(customer_phone)) {
        setPhoneErrorMessage("You must enter a valid phone number")
        setPhoneFilled(false)
        validCustomerPhone = false
      } else if (customer_phone.length !== 10) {
        setPhoneErrorMessage("The phone must be 10 digits long")
        setPhoneFilled(false)
        validCustomerPhone = false
      } else if (!phoneRegex.test(customer_phone)) {
        setPhoneErrorMessage("The phone has an invalid format")
        setPhoneFilled(false)
        validCustomerPhone = false
      } else {
        setPhoneFilled(true)
        validCustomerPhone = true
      }
    }



    let validCustomerEmail = false
    if (!customer_email) {
      setEmailErrorMessage("The customer's email must be entered")
      setEmailFilled(false)
      validCustomerEmail = false
    } else {
      var validator = require("email-validator");
      if (!validator.validate(customer_email)) {
        setEmailErrorMessage("You must enter a valid email")
        setEmailFilled(false)
        validCustomerEmail = false
      } else {
        setEmailFilled(true)
        validCustomerEmail = true
      }
    }

    let zipCodeRegex = new RegExp("^[0-9]{5}$");
    let validCustomerZipCode = false
    if (!customer_addr_zipcode) {
      setValidZipCode(true)
      validCustomerZipCode = true
    } else {
      if (isNaN(customer_addr_zipcode)) {
        setZipCodeErrorMessage("You must enter a valid zip code")
        setValidZipCode(false)
        validCustomerZipCode = false
      } else if (customer_addr_zipcode.length !== 5) {
        setZipCodeErrorMessage("The zip code must be 5 digits long")
        setValidZipCode(false)
        validCustomerZipCode = false
      } else if (!zipCodeRegex.test(customer_addr_zipcode)) {
        setZipCodeErrorMessage("The zip code has an invalid format")
        setValidZipCode(false)
        validCustomerZipCode = false
      } else {
        setValidZipCode(true)
        validCustomerZipCode = true
      }
    }

    if (customer_first_name && customer_last_name && validCustomerPhone && validCustomerEmail && validCustomerZipCode) {
      return true
    } else {
      return false
    }

  };

  useImperativeHandle(ref, () => ({
    validateForm: validateForm
  }));

  return (
    <div style={{ padding: "2rem" }}>
      <TextField
        label="* First Name"
        placeholder="First Name"
        onChange={props.handleChange(setFirstName)}
        defaultValue={customer_first_name}
        fullWidth
        margin="normal"
        error={!firstNameFilled}
      />
      {!firstNameFilled && <FormHelperText style={{color:"red"}}>The customer's first name must be entered</FormHelperText>}
      <TextField
        label="* Last Name"
        placeholder="Last Name"
        onChange={props.handleChange(setLastName)}
        defaultValue={customer_last_name}
        fullWidth
        margin="normal"
        error={!lastNameFilled}
      />
      {!lastNameFilled && <FormHelperText style={{color:"red"}}>The customer's last name must be entered</FormHelperText>}
      <TextField
        label="* Phone No."
        placeholder="Phone No."
        onChange={props.handleChange(setPhoneNumber)}
        defaultValue={customer_phone}
        fullWidth
        margin="normal"
        error={!phoneFilled}
      />
      {!phoneFilled && <FormHelperText style={{color:"red"}}>{phoneErrorMessage}</FormHelperText>}
      <TextField
        label="* Email"
        placeholder="Email"
        onChange={props.handleChange(setEmail)}
        defaultValue={customer_email}
        fullWidth
        margin="normal"
        error={!emailFilled}
      />
      {!emailFilled && <FormHelperText style={{color:"red"}}>{emailErrorMessage}</FormHelperText>}
      <TextField
        label="Street"
        placeholder="Street"
        onChange={props.handleChange(set_customer_addr_street)}
        defaultValue={customer_addr_street}
        fullWidth
        margin="normal"
      />
      <TextField
        label="City"
        placeholder="City"
        onChange={props.handleChange(set_customer_addr_city)}
        defaultValue={customer_addr_city}
        fullWidth
        margin="normal"
      />


      {/*<FormControl fullWidth margin="dense">
        <InputLabel>State</InputLabel>
        <Select
          onChange={props.handleChange(set_customer_addr_state)}
          defaultValue={customer_addr_state}
        >
          {US_STATES.map((opt) => (
            <MenuItem value={opt} key={`us_state_item_${opt}`}>{opt}</MenuItem>
          ))}
        </Select>
      </FormControl>*/}

      <div style={{margin:'6px 0'}}>
        <span>State</span>
        <Autocomplete
          defaultValue={customer_addr_state}
          size="small"
          id="combo-box-demo"
          options={US_STATES.map((opt) => ({
            value: opt.abbreviation,
            label: opt.abbreviation,
          }))}
          getOptionLabel={(option) =>
            `${option.label}`
          }
          style={{ width: 300, marginTop:"10px" }}
          renderInput={(params) => (
            <TextField {...params} placeholder='Search...' variant="outlined" />
          )}

          onChange={(e, value) => {
            // props.handleValChange(setSpecies)(value ?? null);
            // props.handleValChange(setBreed)(null);
            props.handleValChange(set_customer_addr_state)(value ?? null);
          }}

        />
      </div>


      <TextField
        label="Zip Code"
        placeholder="Zip Code"
        onChange={props.handleChange(set_customer_addr_zipcode)}
        defaultValue={customer_addr_zipcode}
        fullWidth
        margin="normal"
        error={!validZipCode}
      />
      {!validZipCode && <FormHelperText style={{color:"red"}}>{zipCodeErrorMessage}</FormHelperText>}
    </div>
  );
});

export default OwnerStep;
