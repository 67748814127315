import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { TextField, FormHelperText, FormControl, FormControlLabel, Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { setHowDidYouHearAboutUs, setAdditionalInformation, setCheckedOptInMarketingEmail } from "utils/slice";

const HEAR_ABOUT_US_OPTIONS = ['Referral from my vet', 'Referral from another emergency provider', 'Referral from pet shop',
  'Referral from friend or family', 'Internet Search', 'Been to VEG before', 'Passed by VEG before',
  'Social Media (Facebook, Instagram, Nextdoor, etc.)', 'Local Event', 'Sign or Billboard',
  'Other (Please tell us below!)'];

const HearAboutUsStep = forwardRef ((props, ref) => {
  const {
    how_did_you_hear_about_us,
    additional_info,
    checked_opt_in_marketing_email,
  } = useSelector((state) => {
    return state.checkin;
  });


  const [howDidYouHearAboutUsFilled, setHowDidYouHearAboutUsFilled] = useState(true)
  const [additionalInformationFilled, setAdditionalInformationFilled] = useState(true)

  const validateForm = () => {
    setHowDidYouHearAboutUsFilled(!!how_did_you_hear_about_us)
    if (how_did_you_hear_about_us) {
      if (how_did_you_hear_about_us === "Other (Please tell us below!)" && !additional_info) {
        setAdditionalInformationFilled(false)
        return false
      } else {
        setAdditionalInformationFilled(true)
        return true
      }
    } else {
      setAdditionalInformationFilled(true)
      return false
    }


  };

  useImperativeHandle(ref, () => ({
    validateForm: validateForm
  }));


  return (
    <div style={{ padding: "2rem" }}>
      <p>
        * How did you hear about us?
      </p>
      <Autocomplete
        defaultValue={how_did_you_hear_about_us}
        id="combo-box-demo"
        options={HEAR_ABOUT_US_OPTIONS}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Search..." variant="outlined" />
        )}
        onChange={(e, value) => {
          props.handleValChange(setHowDidYouHearAboutUs)(value);
        }}
        error={!howDidYouHearAboutUsFilled}
      />
      {!howDidYouHearAboutUsFilled && <FormHelperText style={{color:"red"}}>You must select an option</FormHelperText>}
      <TextField
        multiline
        maxRows={4}
        label='Additional info'
        onChange={props.handleChange(setAdditionalInformation)}
        defaultValue={additional_info}
        fullWidth
        margin="normal"
        error={!additionalInformationFilled}
      />
      {!additionalInformationFilled && <FormHelperText style={{color:"red"}}>The additional info must be entered</FormHelperText>}
      <FormControl component="fieldset" fullWidth margin="dense">
        <FormControlLabel
          control={
            <Checkbox
              checked={checked_opt_in_marketing_email}
              onChange={props.handleCheck(setCheckedOptInMarketingEmail)}
            />
          }
          label={
            <div style={{fontSize: '14px', marginTop: '14px'}}>
              Please keep me updated on VEG and help keep my pet safe from emergencies.
            </div>
          }
        />
      </FormControl>
    </div>
  );
});

export default HearAboutUsStep;
